import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const ZZZAgentsProg: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Agents Progression</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_agents.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Agents Progression</h1>
          <h2>How to make your Agents in Zenless Zone Zero stronger.</h2>
          <p>
            Last updated: <strong>13/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Agents" />
        <p>
          If you want to learn some basic information about Agents, check our
          other guide linked below - this guide will delve deeper into the 3
          systems available in Zenless Zone Zero that will allow to make your
          Agents stronger.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Attributes & Specialties"
            link="/zenless/guides/agents-attributes"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_attributes.jpg"
                alt="Attributes & Specialties"
              />
            }
          />
        </Row>
        <SectionHeader title="Agent Training" />
        <p>
          Training agents enhances their combat capabilities this can be done
          with in game resources. There are multiple different ways to raise the
          power of an Agent.
        </p>
        <h5>Agent Levelling</h5>
        <StaticImage
          src="../../../images/zzz/guides/leveling.webp"
          alt="Guide"
        />
        <p>
          You can raise your agents level using{' '}
          <strong>Investigator Logs</strong>, each level will increase their
          base ATK, DEF and HP.
        </p>
        <h5>Agent Ascension</h5>
        <StaticImage
          src="../../../images/zzz/guides/ascending.webp"
          alt="Guide"
        />
        <p>
          Every 10 levels your Agent’s level will reach a cap, to overcome this
          cap you must <strong>ascend them</strong> using special ascension
          materials. Ascending an Agent will grant them additional ATK, DEF and
          HP stats.
        </p>
        <h5>Agent Skills</h5>
        <StaticImage src="../../../images/zzz/guides/skills.webp" alt="Guide" />
        <p>
          Each Agent has 5 skills each of which can be levelled up using{' '}
          <strong>Dennies</strong> (Mora/Credits/Gold) and levelling materials
          corresponding to the characters element (Fire, Ether, Ice, Physical,
          Electric). You can level the Agents{' '}
          <strong>
            Basic Attack, Dodge, Special Attack, Chain Attack and Assist
          </strong>
          .
        </p>
        <h5>Agent Core Skills</h5>
        <StaticImage
          src="../../../images/zzz/guides/talents.webp"
          alt="Guide"
        />
        <p>
          Each character has a Core Skill and Additional Ability and the Core
          Skill can be leveled up. To do so, you need to reach certain level
          (15, 25, and so on) and this will make the Core passive stronger.
        </p>
        <h5>Agent Mindscapes</h5>
        <StaticImage
          src="../../../images/zzz/guides/mindscape.webp"
          alt="Guide"
        />
        <p>
          To unlock the character Mindscapes you will need to get dupes
          (additional copies). There are <strong>6 passives</strong> (From M1 to
          M6) that can be unlocked and each drastically increases the
          character's performance.
        </p>
        <SectionHeader title="Drive Disks" />
        <StaticImage
          src="../../../images/zzz/guides/drive_2.webp"
          alt="Guide"
        />
        <p>
          <strong>Drive Disks</strong> are items in Zenless Zone Zero that can
          be equipped on Agents to increase their stats and if you{' '}
          <strong>
            combine the available Sets, you will unlock additional traits
          </strong>
          . These are the equivalent of Relics from Honkai: Star Rail. Each
          character has <strong>6 slots for Drive Disks</strong> and in the
          below guide you can find the list of all available sets that you can
          fit into them.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Drive Disks Sets"
            link="/zenless/disk-drives"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_disksets.png"
                alt="Drive Disks Sets"
              />
            }
          />
        </Row>
        <SectionHeader title="W-Engines" />
        <StaticImage src="../../../images/zzz/guides/engine.webp" alt="Guide" />
        <p>
          W-Engines are the Zenless Zone Zero equivalent of Weapons from Genshin
          Impact or Light Cones from Honkai: Star Rail. Here's some more
          information about the system:
        </p>
        <ul>
          <li>
            W-Engines come in 3 rarities:{' '}
            <strong className="s-rank">S-rank</strong>,{' '}
            <strong className="a-rank">A-rank</strong> and{' '}
            <strong className="b-rank">B-rank</strong>.
          </li>
          <li>
            W-Engines increase the Agent's Attack and another stat that changes
            based on the W-Engine,
          </li>
          <li>
            W-Engines also come with a passive effect that activates in the
            combat,
          </li>
          <ul>
            <li>
              W-Engines are restricted to the character's Specialty - so for
              example,{' '}
              <strong>
                only Attack characters can activate the special passive of an
                Attack W-Engine
              </strong>
              .
            </li>
          </ul>
          <li>You can level W-Engines in the same way as you level Agents,</li>
          <li>
            You can obtain W-Engines from the Signal Search, buy them in the
            Gadget Store or obtain them as random drops from some game modes.
          </li>
        </ul>
        <p>
          You can find the list of currently available W-Engines in this guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="W-Engines"
            link="/zenless/w-engines"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_engines.png"
                alt="W-Engines"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZAgentsProg;

export const Head: React.FC = () => (
  <Seo
    title="Agents Progression | Zenless Zone Zero | Prydwen Institute"
    description="How to make your Agents in Zenless Zone Zero stronger."
    game="zzz"
  />
);
